import { lazy } from "react";

const Login = lazy(() => import("../pages/auth/login"));
const ForgotPassword = lazy(() => import("../pages/auth/forgot-password"));
const VerifyOTP = lazy(() => import("../pages/auth/verify-otp"));

export const authRoutes = [
  { path: "/login", Element: Login },
  { path: "/forgot-password", Element: ForgotPassword },
  { path: "/verify-otp", Element: VerifyOTP },
];
