import { Routes, Route, HashRouter } from "react-router-dom";

import Protect from "../pages/auth/protect";

import { useAuth } from "../context/Auth.context";

import Loader from "./loader";
import MapRoutes from "./routes";
import { Suspense } from "react";

export default function Router() {
  const { auth, isLoading } = useAuth();

  return (
    <Suspense fallback={<Loader />}>
      <HashRouter>
        {isLoading ? (
          <Loader />
        ) : (
          <Routes>
            <Route path="/" element={<Protect />} />
            <Route
              path="*"
              element={<MapRoutes isLoggedIn={!!auth?.token} />}
            />
          </Routes>
        )}
      </HashRouter>
    </Suspense>
  );
}
