import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
export default function Protect() {
  const [protectPasswd, setProtectPasswd] = useState<string>();
  const navigation = useNavigate();

  const protectPass = async () => {
    try {
      if (protectPasswd) {
        let res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/cms/auth/password`,
          {
            tool: protectPasswd,
          }
        );
        if (res.data.message === "success") {
          navigation("/login");
        } else {
          toast.error("Password Wrong", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center text-[14px]">
      <div className="flex flex-col gap-y-[34px] items-start">
        <input
          type="text"
          defaultValue={""}
          onChange={(evt) => setProtectPasswd(evt.target.value)}
          placeholder="Password"
          className="bg-transparent outline-none placeholder:text-black"
        />
        <span onClick={protectPass} className="cursor-pointer">
          Submit
        </span>
      </div>
      <ToastContainer />
    </div>
  );
}
