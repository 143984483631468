const getItem = () => {

    let json = localStorage.getItem('blog-user');

    // Parse the JSON string to a JavaScript object of type MyVariableType
    let data: any | null = json ? JSON.parse(json) : null;
    if (!data) {
        data = []
        data.token = ""
    }
    return data
}

const setItem = (data: any) => {
    localStorage.setItem('blog-user', JSON.stringify(data))
}

export {
    getItem,
    setItem
}