import { lazy } from "react";

const Dashboard = lazy(() => import("../pages/dashboard"));
const MemoEditor = lazy(() => import("../pages/memoEditor"));
const Settings = lazy(() => import("../pages/settings"));
const Verify = lazy(() => import("../pages/verify"));
const ChangePass = lazy(() => import("../pages/changepass"));
const OnlyEditor = lazy(() => import("../pages/onlyEditor"));
const Gated = lazy(() => import("../pages/gated"));
const EditGated = lazy(() => import("../pages/editgated"));

export const protectedRoutes = [
  { path: "/main/dashboard", Element: Dashboard },
  { path: "/main/editor", Element: MemoEditor },
  { path: "/main/onlyeditor/:id", Element: OnlyEditor },
  { path: "/main/settings", Element: Settings },
  { path: "/main/verify", Element: Verify },
  { path: "/main/gated", Element: Gated },
  { path: "/main/editgated/:id", Element: EditGated },
  { path: "/main/changepassword", Element: ChangePass },
];
