import React, { useEffect, useMemo, useState, createContext, useContext, useReducer } from "react";
import axios from "axios";

const App = createContext({});

export function useBlogContext() {
    return useContext(App);
}

function reducer(state: any, { type, payload }: any) {
    return {
        ...state,
        [type]: payload
    }
}

const INIT_STATE = {
    user: null
}

export default function Provider({ children }: any) {

    const [state, dispatch] = useReducer(reducer, INIT_STATE);

    const localSave = (type: string, payload: any) => {

        dispatch({
            type: type,
            payload: payload
        })

        localStorage.setItem(`blog-${type}`, JSON.stringify(payload));

    }

    useEffect(() => {
        (async () => {

        })()
    }, [])


    return (
        <App.Provider
            value={useMemo(
                () => [
                    state, { dispatch, localSave }
                ],
                [
                    state, dispatch, localSave
                ]
            )}
        >
            {/* <Toaster
                position="bottom-right"
                reverseOrder={false}
                toastOptions={{
                    duration: 100000
                }}
            /> */}
            {
                children
            }
        </App.Provider>
    )
}