import LinearProgress from "@mui/material/LinearProgress";

const Loader = () => (
  <div className="w-full h-screen flex flex-col gap-y-5 items-center justify-center">
    <p className="text-lg">Loading</p>
    <span className="text-2xl w-[150px] text-black">
      <LinearProgress color="inherit" />
    </span>
  </div>
);

export default Loader;
