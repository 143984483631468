import React, { PropsWithChildren, useContext } from "react";
import { getItem } from "../resource/storage";

type AuthData = { token: string, name: string } | undefined;

type AuthContextType = {
  auth: AuthData;
  isLoading: boolean;
  setAuth: React.Dispatch<React.SetStateAction<AuthData>>;
};

const initialState = {
  auth: undefined,
  setAuth: () => {},
  isLoading: false,
};

const AuthContext = React.createContext<AuthContextType>(initialState);

function AuthProvider(props: PropsWithChildren) {
  const [auth, setAuth] = React.useState<AuthData>(getItem());
  const [isLoading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    const data = getItem();
    setAuth(() => (data.token ? { token: data.token, name: data.name } : undefined));
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth, isLoading }}>
      {props.children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const { auth, setAuth, isLoading } = useContext(AuthContext);

  const createSession = (Auth: AuthData) => setAuth(Auth);
  const destroySession = () => setAuth(undefined);

  return { auth, isLoading, createSession, destroySession };
}

export { AuthProvider, useAuth };
