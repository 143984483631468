import { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { protectedRoutes } from "./protected.routes";
import { authRoutes } from "./auth.routes";

type MapRoutesProps = {
  isLoggedIn: boolean;
};
function MapRoutes(props: MapRoutesProps) {
  const { isLoggedIn } = props;

  const routes = useMemo(
    () => (isLoggedIn ? protectedRoutes : authRoutes),
    [isLoggedIn]
  );

  const fallbackRoute = useMemo(
    () => (isLoggedIn ? "/main/dashboard" : "/login"),
    [isLoggedIn]
  );

  return (
    <Routes>
      {routes.map(({ path, Element }, index) => (
        <Route key={index} path={path} element={<Element />} />
      ))}
      <Route path="*" element={<Navigate to={fallbackRoute} replace />} />
    </Routes>
  );
}

export default MapRoutes;
